import { Outcome } from '@agilelab/plugin-wb-governance-common';
import { DataContractStatusSummary } from '../../hooks/useDataContractStatus';

export const extractErrorMessageFromDataContractResult = (
  result: DataContractStatusSummary,
) => {
  if (!result) return undefined;
  if (!result.isDataContractRoot) return undefined;
  if (!result.lastResult) return undefined;
  if (result.lastResult.details.parsingOutcome === 'failure')
    return 'Malformed Data Contract Evaluation';
  if (result.lastResult.outcome === Outcome.Error)
    return (
      result.lastResult.details.notes?.errorSummary?.message ??
      'Critical Data Contract Violation'
    );
  return undefined;
};

export const getSystemToDcEdgeId = (systemId: string, dcId: string) =>
  `reader-dc:${systemId}:${dcId}`;

export const parseSystemToDcEdgeId = (edgeId: string) => {
  const elements = edgeId.split(':');

  if (!elements[1] || !elements[2]) throw new Error("Couldn't parse");
  return { systemId: elements[1], dcId: elements[2] };
};
