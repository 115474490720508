import React, { useCallback } from 'react';
import { WbAutocomplete, WbChip } from '../WbTextField';
import { FieldActions } from './FieldActions';
import { useGeneralPurposePickerContext } from './GeneralPurposePickerContext';
import { KindEnum, TextFieldValue } from './types';
import { useTheme } from '@material-ui/core';
import { isEqual } from 'lodash';

export const MultiTextField = ({
  title,
  description,
  freeSolo,
  values,
  disabled,
  required,
}: {
  title: string;
  description?: string | undefined;
  freeSolo: boolean;
  values: TextFieldValue[];
  disabled: boolean;
  required: boolean;
}) => {
  const { setSelectedValues, setOpenDialog } = useGeneralPurposePickerContext();
  const theme = useTheme();

  const onClear = useCallback(() => {
    setSelectedValues({ tableValues: [], arbitraryValues: [] });
  }, [setSelectedValues]);

  const handleDelete = (option: TextFieldValue) => () => {
    setSelectedValues(s => ({
      tableValues: s.tableValues.filter(v => !isEqual(v, option)),
      arbitraryValues: s.arbitraryValues?.filter(v => !isEqual(v, option)),
    }));
  };

  const adaptLabel = (option: TextFieldValue) => {
    if (option?.kind === KindEnum.arbitrary) return option?.label;
    const labelKind = option?.kindDisplayName ?? option?.kind;

    return `${labelKind ? `${labelKind}:` : ''} ${option?.label}`;
  };

  const helperText =
    description || 'Select tags from list or insert new ones with Enter key';

  return (
    <WbAutocomplete<any, true, false, any>
      multiple
      fullWidth
      disabled={disabled}
      required={required}
      freeSolo={freeSolo}
      label={title}
      InputProps={{
        endAdornment: !disabled ? (
          <FieldActions onClear={onClear} onOpen={() => setOpenDialog(true)} />
        ) : undefined,
      }}
      onOpen={() => {
        if (!freeSolo) {
          setOpenDialog(true);
        }
      }}
      options={[]}
      helperText={helperText}
      value={values || []}
      onChange={(_event, newValue) => {
        if (!newValue.length) {
          onClear();
          return;
        }

        const newValueAdapted = newValue?.reduce(
          (acc, curr) => {
            if (typeof curr === 'string') {
              acc.arbitraryValues.push({
                kind: KindEnum.arbitrary,
                label: curr,
                value: curr,
              });
            } else {
              if (curr.kind === KindEnum.arbitrary) {
                acc.arbitraryValues.push(curr);
              } else {
                acc.tableValues.push(curr);
              }
            }

            return acc;
          },
          { tableValues: [], arbitraryValues: [] },
        );

        setSelectedValues(newValueAdapted);
      }}
      renderTags={(chips: TextFieldValue[], getTagProps) => {
        return chips.map((option: TextFieldValue, index: number) => (
          <WbChip
            label={adaptLabel(option)}
            {...getTagProps({ index })}
            style={{
              backgroundColor:
                option?.kind === KindEnum.arbitrary
                  ? theme.palette.bkg.secondary
                  : theme.palette.bkg.primary,
            }}
            onDelete={handleDelete(option)}
          />
        ));
      }}
    />
  );
};
