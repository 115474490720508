import React, { useState } from 'react';
import useResizeObserver from './useResizeObserver';

export const useOverflowingElement = <T extends HTMLElement>(
  ref: React.RefObject<T>,
) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  useResizeObserver(val => {
    const {
      clientWidth: width,
      scrollWidth,
      clientHeight: height,
      scrollHeight,
    } = val;
    if (height < scrollHeight || width < scrollWidth) {
      setIsOverflowing(true);
    } else setIsOverflowing(false);
  }, ref);

  return isOverflowing;
};
