import {
  GenericField,
  WbCard,
  WbCardContent,
} from '@agilelab/plugin-wb-platform';
import { Box, makeStyles, Theme } from '@material-ui/core';

import React from 'react';

const useStyles = makeStyles((_theme: Theme) => ({
  drawerContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    width: '100%',
    gap: 50,
    flexWrap: 'wrap',
  },
}));

export const PermissionsDetailDrawer = (props: {
  subject: string[];
  permission: string;
  permissionDescription: string;
  target: string;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.drawerContent}>
      <WbCard title="Detail">
        <WbCardContent>
          <Box className={classes.row}>
            <GenericField
              label="Subject"
              value={
                props.subject.length > 1
                  ? `${props.subject.length} Subjects`
                  : props.subject[0]
              }
              type="string"
            />
            <GenericField
              label="Permission"
              value={props.permission}
              type="string"
            />
            <GenericField
              label="Description"
              value={props.permissionDescription}
              type="string"
            />
            <GenericField label="Target" value={props.target} type="string" />
          </Box>
        </WbCardContent>
      </WbCard>
    </Box>
  );
};
