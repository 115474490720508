import React, { ReactNode } from 'react';
import {
  Box,
  makeStyles,
  Theme,
  useTheme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { Separator, Variant } from './types';
import { MainSeparator } from './separators/MainSeparator';
import { SecondarySeparator } from './separators/SecondarySeparator';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { WbDivider } from '../WbDivider';
import { WbPopover } from '../WbPopover';

type WbBreadcrumbsProps = {
  children: ReactNode;
  variant?: Variant;
  disableLastItemHighlight?: boolean;
  showEllipsis?: boolean;
  hideFirstElement?: boolean;
};

const useStyles = makeStyles<Theme, { i?: number }>(
  theme => ({
    root: {
      '& > ul': {
        listStyle: 'none',
        display: 'flex',
        height: '100%',
        margin: 0,
        padding: 0,
      },
    },
    main: {
      height: '40px',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.white,

      '& $firstItem': {
        paddingLeft: '16px',
        backgroundColor: theme.palette.bkg.secondary,
      },
      '& $lastItem': {
        fontWeight: theme.typography.fontWeightMedium as number,
        color: theme.palette.secondary.dark,
      },
      '& $itemContainer': {
        paddingInline: '16px',
      },
    },
    secondary: {
      height: '18px',
      '& > ul': {
        gap: theme.spacing(1),
      },
      '& $lastItem': {
        color: theme.palette.primary.main,
      },
      '& $firstItem': {},
    },
    item: {
      height: '100%',
      color: theme.palette.secondary.main,
      ...theme.typography.body2,
    },
    firstItem: {},
    lastItem: {},
    itemContainer: {
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    ellipsis: {
      color: theme.palette.text.secondary,
    },
    breadcrumbRow: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    popoverContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      marginTop: '8px',
    },
    smallIcon: {
      width: '12px',
      height: '12px',
    },
  }),
  { name: 'WbBreadcrumbs' },
);

const WbBreadcrumbWrapper = ({
  children,
  i,
  last,
  disableLastItemHighlight,
}: {
  children?: ReactNode;
  variant: Variant;
  i: number;
  last?: boolean;
  disableLastItemHighlight?: boolean;
}) => {
  const classes = useStyles({ i });

  return (
    <Box
      component="li"
      className={clsx(
        classes.itemContainer,
        classes.item,
        i === 0 && classes.firstItem,
        last && !disableLastItemHighlight && classes.lastItem,
      )}
    >
      {children}
    </Box>
  );
};

const separators: Record<Variant, Separator> = {
  main: MainSeparator,
  secondary: SecondarySeparator,
};

const insertSeparators = (
  items: React.JSX.Element[],
  variant: Variant,
  insertAtTheEnd = false,
) => {
  const SeparatorComponent = separators[variant];

  return items.reduce<React.JSX.Element[]>((acc, current, index) => {
    let next = acc;

    if (index < items.length - 1 || insertAtTheEnd) {
      next = next.concat(
        current,
        <SeparatorComponent i={index} key={`separator-${index}`} />,
      );
    } else {
      next.push(current);
    }

    return next;
  }, []);
};

export const WbBreadcrumbs = ({
  children,
  variant = 'main',
  disableLastItemHighlight,
  showEllipsis = false,
  hideFirstElement = false,
}: WbBreadcrumbsProps) => {
  const classes = useStyles({ i: 0 });
  const theme = useTheme();

  const elements = React.Children.toArray(children);
  const validElements = elements.filter(child => React.isValidElement(child));
  let filteredItems = validElements;

  const ellipsisBox = <Box className={classes.ellipsis}>...</Box>;

  if (variant === 'secondary') {
    if (showEllipsis) {
      if (hideFirstElement && validElements.length > 1) {
        // Show: ... > last
        filteredItems = [ellipsisBox, validElements[validElements.length - 1]];
      } else if (validElements.length > 2) {
        // Show: first > ... > last
        filteredItems = [
          validElements[0],
          ellipsisBox,
          validElements[validElements.length - 1],
        ];
      }
    }
  }
  const allItems = filteredItems.map((child, index) => (
    <WbBreadcrumbWrapper
      disableLastItemHighlight={disableLastItemHighlight}
      variant={variant}
      key={index}
      i={index}
      last={index === filteredItems.length - 1}
    >
      {child}
    </WbBreadcrumbWrapper>
  ));

  const insertSeparatorAtTheEnd = variant === 'main' && allItems.length === 1;
  const finalItems = insertSeparators(
    allItems,
    variant,
    insertSeparatorAtTheEnd,
  );

  if (showEllipsis && validElements.length > 1) {
    return (
      <WbPopover
        enterDelay={300}
        enterNextDelay={300}
        component={
          <Box padding={2}>
            <Typography
              variant="overline"
              style={{ color: theme.palette.black }}
            >
              Full Domain Path
            </Typography>

            <Box className={classes.popoverContainer}>
              {elements
                .filter(child => React.isValidElement(child))
                .map((child, index) => (
                  <React.Fragment key={`child-${index}`}>
                    <Box className={classes.breadcrumbRow}>
                      {index === 0 || index === elements.length - 1 ? (
                        <FiberManualRecordIcon className={classes.smallIcon} />
                      ) : (
                        <ArrowForwardIosIcon className={classes.smallIcon} />
                      )}

                      {child}
                    </Box>
                    {index !== elements.length - 1 && (
                      <WbDivider
                        orientation="vertical"
                        height="8px"
                        style={{ marginLeft: '8px' }}
                      />
                    )}
                  </React.Fragment>
                ))}
            </Box>
          </Box>
        }
      >
        <Box component="nav" className={clsx(classes.root, classes[variant])}>
          <Box component="ul">{finalItems}</Box>
        </Box>
      </WbPopover>
    );
  }

  return (
    <>
      <Box component="nav" className={clsx(classes.root, classes[variant])}>
        <Box component="ul">{finalItems}</Box>
      </Box>
    </>
  );
};
