import {
  CustomViewComponent,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import React from 'react';

CustomViewPages.register({
  id: 'marketplace_data_contract_drawer',
  function: function Entity() {
    const configApi = useApi(configApiRef);
    const dataSharingAgreementFieldName =
      configApi.getOptionalString(
        'mesh.marketplace.ui.outputPortPage.dataSharingAgreementField',
      ) ?? 'dataSharingAgreement';
    return (
      <>
        <CustomViewComponent type="grid_list" spacing={2}>
          <CustomViewComponent type="marketplace_data_contract_error_panel" />
          <CustomViewComponent
            type="card"
            title="Data Contract Settings"
            display="flex"
          >
            <CustomViewComponent type="horizontal">
              <CustomViewComponent
                type="string"
                label="Ingestion Mode"
                value='{{ dataContract.settings.ingestionMode | replace("_", " ") if dataContract.settings.ingestionMode else "DATA AT REST" }}'
              />
              <CustomViewComponent
                type="string"
                label="Kind"
                path="outputPortType"
              />
              <CustomViewComponent
                type="string"
                label="On Broken Contract"
                value='{{ dataContract.settings.onBrokenContract | replace("_", " ") if dataContract.settings.onBrokenContract else "RED FLAG" }}'
              />
              <CustomViewComponent
                type="string"
                label="Owner"
                path="_system.owner_display_name"
              />
              <CustomViewComponent
                type="image"
                label="DC"
                path="dataContract.settings.icon"
              />
            </CustomViewComponent>
          </CustomViewComponent>
          <CustomViewComponent
            type="grid_sequence"
            path="_componentsByKind"
            showWhenExists="components"
            showWhen={{
              value: '{{ components | some("consumable", true) }}',
              equals: 'true',
            }}
          >
            <CustomViewComponent
              type="card"
              title="{{ label }}"
              showWhen={{
                value: '{{ components | some("consumable", true) }}',
                equals: 'true',
              }}
            >
              <CustomViewComponent
                type="table"
                showRowWhen={{ value: '{{ consumable }}', notEquals: false }}
                click="showSubcomponent"
                path="components"
              >
                <CustomViewComponent
                  type="string"
                  value="{{ name }}"
                  label="Name"
                />
                <CustomViewComponent
                  type="string"
                  value="{{ description }}"
                  label="Description"
                />
                <CustomViewComponent
                  type="string"
                  value="{{ technology }}"
                  label="Technology"
                />
                <CustomViewComponent
                  type="status"
                  path="_subcomponentDataContractCompliant"
                  width="80px"
                  align="center"
                  label="Status"
                />
              </CustomViewComponent>
            </CustomViewComponent>
          </CustomViewComponent>
          <CustomViewComponent type="marketplace_data_contract_policy_status" />
          <CustomViewComponent
            type="sub_card"
            title="SLA"
            showWhenExists="dataContract.SLA"
          >
            <CustomViewComponent type="container">
              <CustomViewComponent type="new_root" path="dataContract.SLA">
                <CustomViewComponent
                  label="upTime"
                  type="string"
                  path="upTime"
                />
                <CustomViewComponent
                  label="timeliness"
                  type="string"
                  path="timeliness"
                />
                <CustomViewComponent
                  label="interval of change"
                  type="string"
                  path="intervalOfChange"
                />
              </CustomViewComponent>
              <CustomViewComponent
                label="Terms and Conditions"
                type="string"
                path="dataContract.termsAndConditions"
                showWhenExists="dataContract.termsAndConditions"
                colSpan={4}
              />
            </CustomViewComponent>
          </CustomViewComponent>

          <CustomViewComponent type="schema_list" path="dataContract.schema">
            <CustomViewComponent type="grid_sequence" path="">
              <CustomViewComponent type="card" title="{{ name }}">
                <CustomViewComponent
                  type="table"
                  path="columns"
                  treeview="children"
                  hideEmptyColumns
                >
                  <CustomViewComponent
                    type="string"
                    path="dataType"
                    width="15%"
                    label="Type"
                  />
                  <CustomViewComponent
                    type="string"
                    path="name"
                    width="30%"
                    label="Name"
                  />
                  <CustomViewComponent
                    type="string"
                    path="description"
                    width="40%"
                    label="Description"
                  />
                  <CustomViewComponent
                    type="tags"
                    path="tags"
                    width="10%"
                    label="Tags"
                  />
                  <CustomViewComponent
                    type="row_link"
                    label="Semantic Link"
                    path="_semanticlink"
                    width="5%"
                    click="showSemanticLink"
                  />
                </CustomViewComponent>
              </CustomViewComponent>
              <CustomViewComponent type="vspace" />
            </CustomViewComponent>
          </CustomViewComponent>
          <CustomViewComponent
            showWhenExists={dataSharingAgreementFieldName}
            type="card"
            title="Data Sharing Agreement"
          >
            <CustomViewComponent type="container">
              <CustomViewComponent
                type="automatic_fields_list"
                path={dataSharingAgreementFieldName}
                defaults={{ _default: { colSpan: '2' } }}
              />
            </CustomViewComponent>
          </CustomViewComponent>
          <CustomViewComponent
            showWhenExists="quality"
            type="marketplace_data_contract_quality"
          />
        </CustomViewComponent>
      </>
    );
  },
});
