import { createStyles, makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import { useOverflowingElement } from '../../hooks/useOverflowingElement';
import { WbCardActionButton } from '../WbCardActionButton';

const useStyles = makeStyles(() =>
  createStyles({
    truncated: {
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      wordBreak: 'break-word',
    },
  }),
);

export interface ShowMoreElementProps {
  children?: React.ReactNode;
  maxLines?: number;
  showMoreButton?: boolean;
}

export const WbShowMoreElement: React.FC<ShowMoreElementProps> = ({
  children,
  maxLines,
  showMoreButton = false,
}) => {
  const classes = useStyles();
  const ref = React.useRef<HTMLSpanElement>(null);
  const isOverflowing = useOverflowingElement(ref);
  const [showMore, setShowMore] = React.useState(false);

  return (
    <>
      <Tooltip
        title={typeof children === 'string' && isOverflowing ? children : ''}
      >
        <span
          className={!showMore ? classes.truncated : ''}
          ref={ref}
          style={
            !showMore && maxLines ? { WebkitLineClamp: maxLines } : undefined
          }
        >
          {children}
        </span>
      </Tooltip>

      {showMoreButton && (isOverflowing || showMore) && (
        <div>
          <WbCardActionButton
            variant="text"
            label={!showMore ? 'More' : 'Less'}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShowMore(v => !v);
            }}
          />
        </div>
      )}
    </>
  );
};
