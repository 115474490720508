import React from 'react';
import { EntityRefLinks } from '@backstage/plugin-catalog-react';
import { CustomViewRegister } from '../CustomView/registry';
import {
  Box,
  createStyles,
  Grid,
  ImageList,
  ImageListItem,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { WbFieldLabel } from '../WbFieldLabel';
import LanguageIcon from '@material-ui/icons/Language';
import { Link } from '@backstage/core-components';
import { IconComponent } from '@backstage/core-plugin-api';
import { CustomViewComponent } from '../CustomView';
import { computeItem } from './utility';

const useStyles = makeStyles(theme =>
  createStyles({
    link: { ...theme.typography.body1 },
    label: {
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      lineHeight: '14px',
    },
    svgIcon: {
      display: 'inline-block',
      '& svg': {
        display: 'inline-block',
        fontSize: 'inherit',
        verticalAlign: 'baseline',
      },
    },
  }),
);

CustomViewRegister.register({
  id: 'link',
  function: function LinkView(props: Record<string, any>) {
    const classes = useStyles();
    const theme = useTheme();
    let values = props.value;
    if (!Array.isArray(values)) {
      values = [values];
    }

    if (values.length === 0 && props.default) {
      return (
        <CustomViewComponent
          type="string"
          value={props.default}
          label={props.label ?? ''}
          colSpan={props.colSpan ?? '1'}
        />
      );
    }
    switch (props.mode) {
      case 'linear':
      case 'table':
        return (
          <EntityRefLinks
            entityRefs={values}
            defaultKind={props.kind}
            className={classes.link}
          />
        );
      default:
    }
    return (
      <Grid
        item
        xs={computeItem(props.colSpan || 1, 1)}
        sm={computeItem(props.colSpan || 1, 2)}
        lg={computeItem(props.colSpan || 1, 4)}
        style={{
          padding: `${theme.spacing(2)}px 0`,
          paddingRight: `${theme.spacing(4)}px`,
          paddingLeft: 0,
        }}
      >
        <WbFieldLabel label={props.label} />
        <EntityRefLinks
          entityRefs={values}
          defaultKind={props.kind}
          className={classes.link}
        />
      </Grid>
    );
  },
});

function IconLink(props: {
  href: string;
  text?: string;
  Icon?: IconComponent;
}) {
  const { href, text, Icon } = props;
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box mr={1} className={classes.svgIcon}>
        <Typography component="div">
          {Icon ? <Icon /> : <LanguageIcon />}
        </Typography>
      </Box>
      <Box flexGrow="1">
        <Link to={href} target="_blank" rel="noopener">
          {text || href}
        </Link>
      </Box>
    </Box>
  );
}

interface LinksGridListItem {
  href: string;
  text?: string;
  Icon?: IconComponent;
}
CustomViewRegister.register({
  id: 'link_grid_list',
  function: function LinkListView(props: Record<string, any>) {
    const list: LinksGridListItem[] = props.value;
    return (
      <ImageList rowHeight="auto" cols={1}>
        {list.map(({ text, href, Icon }, i) => (
          <ImageListItem key={i}>
            <IconLink href={href} text={text ?? href} Icon={Icon} />
          </ImageListItem>
        ))}
      </ImageList>
    );
  },
});
