import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export const useBreakpointValue = <TValue>(values: {
  [key in Breakpoint]?: TValue;
}): TValue | undefined => {
  const theme = useTheme();

  const matches = {
    xs: useMediaQuery(theme.breakpoints.up('xs')),
    sm: useMediaQuery(theme.breakpoints.up('sm')),
    md: useMediaQuery(theme.breakpoints.up('md')),
    lg: useMediaQuery(theme.breakpoints.up('lg')),
    xl: useMediaQuery(theme.breakpoints.up('xl')),
  };

  const validBreakpoints = Object.entries(matches)
    .filter(
      ([breakpoint, isMatch]) =>
        Object.keys(values).includes(breakpoint as Breakpoint) && isMatch,
    )
    .map(([key]) => key as Breakpoint);

  const largestBreakpoint = validBreakpoints.pop();

  if (!largestBreakpoint) {
    return undefined; // Or return a default value if necessary
  }

  return values[largestBreakpoint];
};
