import {
  BasicPermission,
  createPermission,
  ResourcePermission,
} from '@backstage/plugin-permission-common';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

/**
 * Resource type binds a permission request the correctly typed integration router and condition exports
 * e.g.
 * we could have a dedicated integration router for data products and another one for domain.
 * each one will load from catalog the corresponding entities
 */
export const RESOURCE_TYPE_WITBOOST_ENTITY = 'witboost-entity';

export const catalogPlatformCreatePermission = createPermission({
  name: 'catalog.platform.create',
  attributes: {},
});

export const catalogPlatformDeletePermission = createPermission({
  name: 'catalog.platform.delete',
  attributes: {},
});

export const catalogPlatformRefreshPermission = createPermission({
  name: 'catalog.platform.refresh',
  attributes: {},
});

export const builderDpSnapshotCreatePermission = createPermission({
  name: 'builder.dp.snapshot.create',
  attributes: {},
  resourceType: RESOURCE_TYPE_WITBOOST_ENTITY,
});

export const builderDpReleasePermission = createPermission({
  name: 'builder.dp.release',
  attributes: {},
  resourceType: RESOURCE_TYPE_WITBOOST_ENTITY,
});

export const builderDpCommitPermission = createPermission({
  name: 'builder.dp.commit',
  attributes: {},
  resourceType: RESOURCE_TYPE_WITBOOST_ENTITY,
});

export const builderDpDeployPermission = (env: string) => {
  return createPermission({
    name: `builder.dp.deploy.${env}`,
    attributes: {},
    resourceType: RESOURCE_TYPE_WITBOOST_ENTITY,
  });
};

export const builderDpPoliciesTest = createPermission({
  name: 'builder.dp.policies.test',
  attributes: {},
  resourceType: RESOURCE_TYPE_WITBOOST_ENTITY,
});

export const builderDpNewVersion = createPermission({
  name: 'builder.dp.newversion',
  attributes: {},
  resourceType: RESOURCE_TYPE_WITBOOST_ENTITY,
});

export const builderSoftwareCatalogView = createPermission({
  name: 'builder.software-catalog.view',
  attributes: {},
});

export const cgpEntityEdit = createPermission({
  name: 'cgp.entity.edit',
  attributes: {},
});

export const cgpEntityView = createPermission({
  name: 'cgp.entity.view',
  attributes: {},
});

export const platformSettingsEditPermission = createPermission({
  name: 'platform.settings.edit',
  attributes: {},
});

export const platformCustomViewEditPermission = createPermission({
  name: 'platform.custom-view.edit',
  attributes: {},
});

export const practiceShaperEditPermission = createPermission({
  name: 'practice-shaper.edit',
  attributes: {},
});

export const practiceShaperImportPermission = createPermission({
  name: 'practice-shaper.import',
  attributes: {},
});

export const rbacRoleCreatePermission = createPermission({
  name: 'rbac.role.create',
  attributes: {},
});

export const rbacRoleEditPermission = createPermission({
  name: 'rbac.role.edit',
  attributes: {},
});

export const cgpPermissions: () => (
  | ResourcePermission
  | BasicPermission
)[] = () => [cgpEntityView, cgpEntityEdit];

export const platformEntitiesPermissions: () => (
  | ResourcePermission
  | BasicPermission
)[] = () => [
  catalogEntityCreatePermission,
  catalogPlatformCreatePermission,
  catalogPlatformDeletePermission,
  catalogPlatformRefreshPermission,
];

export const platformSettingsPermissions: () => (
  | ResourcePermission
  | BasicPermission
)[] = () => [platformSettingsEditPermission, platformCustomViewEditPermission];

export const practiceShaperPermissions: () => (
  | ResourcePermission
  | BasicPermission
)[] = () => [practiceShaperEditPermission, practiceShaperImportPermission];

export const rbacPermissions: () => (
  | ResourcePermission
  | BasicPermission
)[] = () => [rbacRoleCreatePermission, rbacRoleEditPermission];
