import {
  CustomViewComponent,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import React from 'react';

CustomViewPages.register({
  id: 'marketplace_search_system',
  function: function Entity() {
    return (
      <CustomViewComponent type="card_content">
        <CustomViewComponent type="hbox">
          <CustomViewComponent type="container" size={3}>
            <CustomViewComponent
              label="Description"
              value="{{description}}"
              type="string"
              hideWhenExists="_expanded"
              colSpan="4"
            />
            <CustomViewComponent
              label="Description"
              value="{{description}}"
              type="string"
              showWhenExists="_expanded"
              maxLines="10"
              colSpan="4"
            />
            <CustomViewComponent
              label="Value Proposition"
              value="{{business_attributes.valueProposition}}"
              type="string"
              showWhenExists="_expanded"
              maxLines="10"
              colSpan="4"
            />
            <CustomViewComponent
              label="Visibility"
              value="{{security_attributes.visibility}}"
              type="string"
              showWhenExists="_expanded"
              colSpan="2"
            />
            <CustomViewComponent
              label="Confidentiality"
              value="{{security_attributes.confidentiality}}"
              type="string"
              showWhenExists="_expanded"
              colSpan="2"
            />
            <CustomViewComponent
              label="Owner"
              value="{{dataProductOwnerDisplayName}}"
              type="string"
              showWhenExists="_expanded"
              colSpan="2"
            />
            <CustomViewComponent
              label="Contact"
              value="{{email}}"
              type="string"
              showWhenExists="_expanded"
              colSpan="2"
            />
            <CustomViewComponent
              title="Related Business Concepts"
              type="tags"
              path="businessConcepts"
              showWhenExists="businessConcepts"
              showWhen={{
                value: '{{businessConcepts.length}}',
                notEquals: 0,
              }}
              colSpan="4"
            />
          </CustomViewComponent>
          <CustomViewComponent type="group" borderLeft>
            <CustomViewComponent
              label="Published Date"
              value="{{_computedInfo.publishedAt}}"
              type="date"
              showWhenExists="_computedInfo.publishedAt"
              colSpan="4"
            />
            <CustomViewComponent
              label="Version"
              value="{{version}}"
              type="string"
              showWhenExists={['_expanded', 'tags.length']}
              colSpan="4"
            />
            <CustomViewComponent
              title="Tags"
              type="tags"
              path="tags"
              showWhen={{
                value: '{{tags.length}}',
                notEquals: 0,
              }}
              showWhenExists="_expanded"
              colSpan="4"
            />
          </CustomViewComponent>
        </CustomViewComponent>
      </CustomViewComponent>
    );
  },
});
