import {
  AtomicFilter,
  CollatorType,
  FilterOperator,
  JsonObjectSearchFilterVisitor,
  MARKETPLACE_PROJECTS_CONSUMABLE,
} from '@agilelab/plugin-wb-search-common';
import { useApi } from '@backstage/core-plugin-api';
import { JsonObject } from '@backstage/types';
import useAsync from 'react-use/lib/useAsync';
import { witboostSearchApiRef } from '../api';
import { resolveAvailableValues } from '../filterResolvers';
import {
  FacetedValues,
  FilterResolversOverride,
  SearchFiltersContext,
} from '../types';
import { useSearchFilterConfig } from './useSearchFilterConfig';
import { ConsumableMode } from '@agilelab/plugin-wb-marketplace-common';
import { useFacetedValues } from './useFacetedValues';

export const useSearchFilters = (
  type: CollatorType,
  filterResolversOverride: FilterResolversOverride = {},
  currentFilters?: JsonObject,
  queryTerm?: string,
): SearchFiltersContext => {
  const { filtersConfig } = useSearchFilterConfig();
  const searchApi = useApi(witboostSearchApiRef);

  const consumableFilterJsonObject = new AtomicFilter(
    MARKETPLACE_PROJECTS_CONSUMABLE.field,
    FilterOperator.IN,
    [ConsumableMode.Consumable, ConsumableMode.HasConsumableChild],
  ).accept(new JsonObjectSearchFilterVisitor());

  // We exclude values related to not consumable entities
  const availableValues = useAsync(async () => {
    return resolveAvailableValues(
      type,
      filtersConfig,
      searchApi,
      filterResolversOverride,
      consumableFilterJsonObject,
    );
  }, [type, filtersConfig, filterResolversOverride]);

  const facetedValues = useAsync(async () => {
    const raw = await searchApi.getAvailableFilterValues(type, {
      currentFilters,
      queryTerm,
    });

    const res: FacetedValues = {};

    // transform into an object where the value of each filter is mapped to its count for convenience
    Object.entries(raw).forEach(([label, counts]) => {
      res[label] = counts.reduce<Record<string, number>>(
        (acc, { value, count }) => {
          acc[value] = count ? parseInt(count, 10) : 0;
          return acc;
        },
        {},
      );
    });

    return res;
  }, [type, filtersConfig, currentFilters, queryTerm]);

  const enrichedValues = useFacetedValues(
    availableValues.value,
    facetedValues.value,
    filtersConfig,
  );

  return {
    config: filtersConfig,
    availableValues,
    facetedValues,
    enrichedValues,
  };
};
