import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const Dot = createSvgIcon(
  <>
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="currentColor" />
    </svg>
  </>,
  'DotOP',
);
