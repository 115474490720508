import React from 'react';
import { CustomViewRegister } from '../CustomView/registry';
import { DataPathContextProvider } from '../../context';
import { GridSize } from '@material-ui/core';

CustomViewRegister.register({
  id: 'new_root',
  function: function NewRoot(props: Record<string, any>) {
    return (
      <DataPathContextProvider path={props.path}>
        {props.children}
      </DataPathContextProvider>
    );
  },
});

export function computeItem(colSpan: number, gridColumns = 4) {
  // 12 is a full row in MUI grid
  const factor = 12 / gridColumns;

  return Math.min(12, colSpan * factor) as GridSize;
}
