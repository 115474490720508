/*
 * This hook is copied from Backstage 1.25.2 (packages/core-components/src/hooks/useQueryParamState.ts)
 * CHANGES:
 *  - In the original hook when multiple params where changed in a short period of time,
 *  some of the values where lost because the first changes overrode the shared searchParams(after a small delay caused by the debounce)
 *  with stale values for the next params changed and reverted them since each param listens to the searchParams changes and is changed consequently;
 *  A workaround is added so that after a param is changed locally, its value can't be overridden by external searchParams changes until itseld is propagated to the searchParams
 */
import { isEqual } from 'lodash';
import qs, { ParsedQs } from 'qs';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function stringify(queryParams: any): string {
  // Even though these setting don't look nice (e.g. escaped brackets), we should keep
  // them this way. The current syntax handles all cases, including variable types with
  // arrays or strings.
  return qs.stringify(queryParams, {
    strictNullHandling: true,
  });
}

function parse(queryString: string) {
  return qs.parse(queryString, {
    arrayLimit: 100,
    ignoreQueryPrefix: true,
    strictNullHandling: true,
  });
}

function extractState<T>(queryString: string, stateName: string): T {
  const queryParams = parse(queryString);
  return queryParams[stateName] as T;
}

function joinQueryString(
  queryString: string,
  stateName: string,
  state: any,
): string {
  const queryParams = {
    ...parse(queryString),
    [stateName]: state,
  };
  return stringify(queryParams);
}

type SetQueryParams<T> = React.Dispatch<React.SetStateAction<T>>;

export function useQueryParamState<T extends ParsedQs | undefined | string>(
  stateName: string,
  debounceTime: number = 250,
  initValue?: T,
): [T, SetQueryParams<T>] {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsString = searchParams.toString();
  const initialState =
    extractState<T>(searchParamsString, stateName) ?? initValue!;
  const [queryParamState, setQueryParamState] = useState<T>(initialState);

  const synchDebounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    // if still debouncing a change, prevent the external searchParams change to override the local value
    if (synchDebounceTimer.current) return;

    const newState = extractState<T>(searchParamsString, stateName);

    // reacts to external searchParams changes by overriding the local value for this param if it has changed
    setQueryParamState((oldState: T): T => {
      if (isEqual(newState, oldState)) {
        return oldState;
      }
      return newState ?? oldState;
    });
  }, [searchParamsString, setQueryParamState, stateName]);

  useEffect(() => {
    synchDebounceTimer.current = setTimeout(() => {
      // workaround to get the most updated params, we could instead use setSearchParams's updater function to get the latest value if only it worked (https://github.com/remix-run/react-router/issues/9757)
      const oldSearchParamsString = new URLSearchParams(
        window.location.search,
      ).toString();

      const queryString = joinQueryString(
        oldSearchParamsString,
        stateName,
        queryParamState,
      );
      // propagate the local state change to the shared searchParams if the local state differs from it
      if (queryString !== oldSearchParamsString) {
        setSearchParams(queryString, { replace: true });
      }
    }, debounceTime);

    return () => {
      if (synchDebounceTimer.current) clearTimeout(synchDebounceTimer.current);
    };
  }, [
    setSearchParams,
    queryParamState,
    searchParamsString,
    stateName,
    debounceTime,
  ]);

  return [queryParamState, setQueryParamState];
}
