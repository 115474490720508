import {
  CustomViewComponent,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import React from 'react';

CustomViewPages.register({
  id: 'marketplace_search_component',
  function: function Entity() {
    return (
      <CustomViewComponent type="card_content">
        <CustomViewComponent type="hbox">
          <CustomViewComponent type="container" size={3}>
            <CustomViewComponent
              label="Description"
              value="{{ description }}"
              type="string"
              hideWhenExists="_expanded"
              colSpan="4"
            />
            <CustomViewComponent
              label="Description"
              value="{{ description }}"
              type="string"
              showWhenExists="_expanded"
              maxLines="10"
              colSpan="4"
            />
            <CustomViewComponent
              label="Platform"
              value="{{ platform }}"
              type="string"
              showWhenExists="_expanded"
              colSpan="1"
            />
            <CustomViewComponent
              label="Technology"
              value="{{ technology }}"
              type="string"
              showWhenExists="_expanded"
              colSpan="1"
            />
            <CustomViewComponent
              label="Intended Usage"
              value="{{ dataSharingAgreement.intendedUsage }}"
              type="string"
              showWhenExists="_expanded"
              colSpan="2"
            />
            <CustomViewComponent
              label="Up Time"
              value="{{ dataContract.SLA.upTime }}"
              type="string"
              showWhenExists="_expanded"
              colSpan="1"
            />
            <CustomViewComponent
              label="Refresh Rate"
              value="{{ dataContract.SLA.intervalOfChange }}"
              type="string"
              showWhenExists="_expanded"
              colSpan="1"
            />
            <CustomViewComponent
              label="Timeliness"
              value="{{ dataContract.SLA.timeliness }}"
              type="string"
              showWhenExists="_expanded"
              colSpan="2"
            />
            <CustomViewComponent
              label="Tags"
              type="tags"
              path="tags"
              showWhen={{
                value: '{{tags.length}}',
                notEquals: 0,
              }}
              colSpan="4"
            />
          </CustomViewComponent>
          <CustomViewComponent type="group" borderLeft>
            <CustomViewComponent
              type="date"
              value="{{ _computedInfo.publishedAt }}"
              showWhenExists="_computedInfo.publishedAt"
              label="Published Date"
              colSpan="4"
            />
            <CustomViewComponent
              label="Version"
              value="{{ version }}"
              type="string"
              showWhenExists={['_expanded', 'tags.length']}
              colSpan="4"
            />
          </CustomViewComponent>
        </CustomViewComponent>
      </CustomViewComponent>
    );
  },
});
