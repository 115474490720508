import { ReservedCatalogInfoFields } from './types';

export const SKELETON_FILE_DIRECTIVE = '%SKELETON';
export const PARAMETERS_FILE = 'parameters.yaml';

const systemReservedFields: ReservedCatalogInfoFields['system'] = {
  name: {
    path: 'metadata.name',
    skeletonParameter: '__name__',
  },
  version: {
    path: 'spec.mesh.version',
    skeletonParameter: '__version__',
  },
};

const componentReservedFields: ReservedCatalogInfoFields['component'] = {
  name: { path: 'metadata.name', skeletonParameter: '__name__' },
  system: { path: 'spec.system', skeletonParameter: '__system__' },
  version: {
    path: 'spec.mesh.version',
    skeletonParameter: '__version__',
  },
  dependsOn: {
    path: 'spec.mesh.dependsOn',
    skeletonParameter: 'dependsOn',
  },
};

export const RESERVED_CATALOG_INFO_FIELDS: ReservedCatalogInfoFields = {
  system: systemReservedFields,
  component: componentReservedFields,
};
