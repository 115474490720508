import {
  ReservedCatalogInfoField,
  ReservedCatalogInfoFields,
  SkeletonParameters,
} from './types';
import { RESERVED_CATALOG_INFO_FIELDS } from './constants';
import _ from 'lodash';

export function updateReservedParameters<
  T extends keyof ReservedCatalogInfoFields,
>(options: {
  entityType: T;
  parameters: SkeletonParameters;
  updates: Map<keyof ReservedCatalogInfoFields[T], any>;
}): SkeletonParameters {
  const updatedParameters = _.cloneDeep(options.parameters.parameters);
  const fields = RESERVED_CATALOG_INFO_FIELDS[options.entityType];

  for (const [key, value] of options.updates.entries()) {
    const field = fields[key] as ReservedCatalogInfoField;
    _.set(updatedParameters, field.skeletonParameter, value);
  }

  return { ...options.parameters, parameters: updatedParameters };
}
