import {
  Engine,
  EngineDisplayLabel,
  Policy,
  PolicySelector,
} from '@agilelab/plugin-wb-governance-common';
import {
  WbTagsTableCell,
  WbCardContent,
  GenericGridItem,
  WbGenericFieldGrid,
  WbTruncatedTypographyWrapper,
  WbCard,
} from '@agilelab/plugin-wb-platform';
import { Box } from '@material-ui/core';
import React from 'react';
import {
  snakeCaseToTitleCase,
  toHumanReadableInteractionType,
} from '@agilelab/plugin-wb-platform-common';
import { StatusField } from '../../../common/Field/StatusField';
import { GovernanceRegistryPoliciesSeverity } from '../../../GovernanceRegistryPage/GovernanceRegistryPolicies/GovernanceRegistryPoliciesSeverity';
import { capitalize } from 'lodash';
import { Link } from '@backstage/core-components';
import { useResourceTypes } from '../../../../hooks/useResourceTypes';
import { removeEndpointValidationUrl } from '../../../../utils/governanceUtils';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

function extractSelectorSummary(selectors: PolicySelector[]): string {
  return selectors
    .map((selector: PolicySelector) => {
      const values =
        selector.values.length > 1
          ? `(${selector.values.join(', ')})`
          : selector.values[0];
      return `${selector.path} ${
        selector.values.length > 1 ? 'in' : '='
      } ${values}`;
    })
    .join(' AND ');
}

interface Props {
  policy: Policy;
}
export const GovernanceTestOverviewPolicyInfo: React.FC<Props> = props => {
  const resourceType = props.policy.resourceType;
  const configApi = useApi(configApiRef);

  const resourceTypeDisplayName =
    useResourceTypes({
      filters: { name: resourceType },
    }).get(resourceType)?.displayName ?? snakeCaseToTitleCase(resourceType);

  const fields: GenericGridItem[] = [
    {
      label: 'ID',
      value: props.policy.policyId,
      type: 'string',
      colSpan: { xs: 2, sm: 2 },
    },
    {
      label: 'Description',
      value: props.policy.description,
      type: 'string',
      colSpan: { xs: 2, sm: 2 },
    },
    {
      label: 'Version',
      type: 'string',
      value: props.policy.version,
      colSpan: { xs: 2, sm: 1 },
    },
    {
      label: 'Resource Preprocessing',
      type: 'string',
      value: snakeCaseToTitleCase(props.policy.strategy),
      colSpan: { xs: 2, sm: 1 },
    },
  ];

  if (props.policy.severity) {
    fields.push({
      label: 'Severity',
      type: 'string',
      value: (
        <Box>
          <GovernanceRegistryPoliciesSeverity
            severity={props.policy.severity}
            style={{
              flexDirection: 'row',
              gap: 2,
              flexGrow: 0,
              justifyContent: 'flex-start',
            }}
          />
        </Box>
      ),
      colSpan: { xs: 2, sm: 1 },
    });
  }

  fields.push({
    label: 'Scope',
    type: 'string',
    value: resourceTypeDisplayName,
    colSpan: { xs: 2, sm: 1 },
  });

  fields.push({
    label: 'Status',
    type: 'string',
    value: <StatusField status={props.policy.status} />,
    colSpan: { xs: 2, sm: 1 },
  });

  fields.push({
    label: 'Context',
    type: 'string',
    value: snakeCaseToTitleCase(props.policy.context),
    colSpan: { xs: 2, sm: 1 },
  });

  fields.push({
    label: 'Timing',
    type: 'string',
    value: snakeCaseToTitleCase(props.policy.timing),
    colSpan: { xs: 2, sm: 1 },
  });

  fields.push({
    label: 'Trigger',
    type: 'string',
    value: snakeCaseToTitleCase(props.policy.trigger),
    colSpan: { xs: 2, sm: 1 },
  });

  if (!!props.policy.cronExpression) {
    fields.push({
      label: 'Cron expression',
      type: 'string',
      value: snakeCaseToTitleCase(props.policy.cronExpression),
      colSpan: { xs: 2, sm: 1 },
    });
  }

  fields.push({
    label: 'Interaction Type',
    type: 'string',
    value: toHumanReadableInteractionType(props.policy.interactionType),
    colSpan: { xs: 2, sm: 1 },
  });

  fields.push({
    label: 'Engine',
    type: 'string',
    value: EngineDisplayLabel[props.policy.engine as Engine],
    colSpan: { xs: 2, sm: 1 },
  });

  if (props.policy.tags && props.policy.tags.length > 0) {
    fields.push({
      label: 'Tags',
      type: 'string',
      value: (
        <WbTagsTableCell
          tags={props.policy.tags.map(t => {
            return { tagFQN: t };
          })}
        />
      ),
      colSpan: { xs: 2, sm: 1 },
    });
  }

  if (props.policy.environments && props.policy.environments.length > 0) {
    fields.push({
      label: 'Environment',
      type: 'string',
      value: props.policy.environments.map((env: string) => capitalize(env))[0],
      colSpan: { xs: 2, sm: 1 },
    });
  }

  if (props.policy.selectors && props.policy.selectors.length > 0) {
    fields.push({
      label: 'Selectors',
      type: 'string',
      value: extractSelectorSummary(props.policy.selectors),
      colSpan: { xs: 4, sm: 4, lg: 4 },
    });
  }

  if (props.policy.externalSpecific?.externalUrl) {
    fields.push({
      label: 'Target URL',
      colSpan: { xs: 4, sm: 4, lg: 4 },
      value: (
        <Link to={props.policy.externalSpecific.externalUrl}>
          <WbTruncatedTypographyWrapper
            value={props.policy.externalSpecific?.externalUrl}
          />
        </Link>
      ),
    });
  }

  if (props.policy.governanceAgentSpec?.url) {
    fields.push({
      label: 'Validation URL',
      colSpan: { xs: 4, sm: 4, lg: 4 },
      value: (
        <Link to={props.policy.governanceAgentSpec?.url}>
          <WbTruncatedTypographyWrapper
            value={removeEndpointValidationUrl(
              props.policy.governanceAgentSpec?.url,
              configApi,
            )}
          />
        </Link>
      ),
    });
  }

  return (
    <WbCard title="Policy">
      <WbCardContent>
        <WbGenericFieldGrid fields={fields} />
      </WbCardContent>
    </WbCard>
  );
};
