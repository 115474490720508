import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { CustomViewRegister } from '../CustomView';
import { GenericField, ExpandableItem } from '../DisplayValue';
import { Grid, Typography, useTheme, Box } from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { computeItem } from './utility';

CustomViewRegister.register({
  id: 'string',
  function: function StringView(props: Record<string, any>) {
    const theme = useTheme();
    const maxLines = props.maxLines;
    const showMoreButton = props.showMoreButton ?? true;
    const value = props.value;
    switch (props.mode) {
      case 'table':
        return (
          <Typography>
            <ExpandableItem
              value={value}
              href={props.href}
              maxLines={maxLines}
              showMoreButton={showMoreButton}
            />
          </Typography>
        );
      case 'linear':
        return <GenericField type="string" maxLines={maxLines} {...props} />;
      default:
    }
    return (
      <Grid
        item
        xs={computeItem(props.colSpan || 1, 1)}
        sm={computeItem(props.colSpan || 1, 2)}
        lg={computeItem(props.colSpan || 1, 4)}
        style={{
          padding: `${theme.spacing(2)}px 0`,
          paddingRight: `${theme.spacing(4)}px`,
          paddingLeft: 0,
        }}
      >
        <GenericField
          maxLines={maxLines}
          gridItem={false}
          {...props}
          showMoreButton={showMoreButton}
        />
      </Grid>
    );
  },
});

CustomViewRegister.register({
  id: 'profile',
  function: function StringView(props: Record<string, any>) {
    const catalogApi = useApi(catalogApiRef);
    const theme = useTheme();
    const maxLines = props.maxLines;
    const showMoreButton = props.showMoreButton ?? true;
    let value = props.value;
    const { value: entity } = useAsync(() => catalogApi.getEntityByRef(value));
    if (entity) {
      if (!!entity.spec && !!entity.spec.profile) {
        const profile = entity.spec.profile as { [key: string]: any };
        if (!!profile.displayName && !!profile.email) {
          value = `${profile.displayName} <${profile.email}>`;
        }
      }
    }
    switch (props.mode) {
      case 'table':
        return <Typography>{value}</Typography>;
      case 'linear':
        return <GenericField maxLines={maxLines} {...props} type="string" />;
      default:
    }
    return (
      <Grid
        item
        xs={computeItem(props.colSpan || 1, 1)}
        sm={computeItem(props.colSpan || 1, 2)}
        lg={computeItem(props.colSpan || 1, 4)}
        style={{
          padding: `${theme.spacing(2)}px 0`,
          paddingRight: `${theme.spacing(4)}px`,
          paddingLeft: 0,
        }}
      >
        <GenericField
          maxLines={maxLines}
          showMoreButton={showMoreButton}
          gridItem={false}
          {...props}
          contentProps={{ style: { wordBreak: 'break-all' } }}
        />
      </Grid>
    );
  },
});

CustomViewRegister.register({
  id: 'date',
  function: function DateView(props: Record<string, any>) {
    const theme = useTheme();

    const value = props.value;
    return (
      <Grid
        item
        xs={computeItem(props.colSpan || 1, 1)}
        sm={computeItem(props.colSpan || 1, 2)}
        lg={computeItem(props.colSpan || 1, 4)}
        style={{
          padding: `${theme.spacing(2)}px 0`,
          paddingRight: `${theme.spacing(4)}px`,
          paddingLeft: 0,
        }}
      >
        <GenericField
          type={value === '' ? 'string' : 'date'}
          gridItem={false}
          value={value}
          label={props.label}
        />
      </Grid>
    );
  },
});

CustomViewRegister.register({
  id: 'title',
  function: function StringView(props: Record<string, any>) {
    return (
      <Typography variant="h6" style={{ paddingLeft: 8 }}>
        {props.value}
      </Typography>
    );
  },
});

CustomViewRegister.register({
  id: 'image',
  function: function ImageView(props: Record<string, any>) {
    if ((props.value || '') === '') return <></>;
    return (
      <Box display="flex" justifyContent="center">
        <img
          alt={props.title || ''}
          src={props.value}
          style={{ maxHeight: props.maxHeight ?? 250 }}
        />
      </Box>
    );
  },
});
