import {
  Metric,
  MetricSelector,
  MetricThresholdColor,
} from '@agilelab/plugin-wb-governance-common';
import {
  WbTagsTableCell,
  WbCardContent,
  useDefaultThresholdColors,
  GenericGridItem,
  WbGenericFieldGrid,
  WbTruncatedTypographyWrapper,
  WbCard,
} from '@agilelab/plugin-wb-platform';
import {
  snakeCaseToTitleCase,
  toHumanReadableInteractionType,
} from '@agilelab/plugin-wb-platform-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { StatusField } from '../../../common/Field/StatusField';
import { fromExpressionToLabel } from '../../../MetricWizard/MetricMetadataForm/inputs/MetricThresholdPicker';
import { capitalize } from 'lodash';
import { Link } from '@backstage/core-components';
import { useResourceTypes } from '../../../../hooks/useResourceTypes';

const useStyles = makeStyles(() => ({
  tresholdsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '30px',
    rowGap: '10px',
    alignItems: 'center',
  },
}));

function extractSelectorSummary(selectors: MetricSelector[]): string {
  return selectors
    .map((selector: MetricSelector) => {
      const values =
        selector.values.length > 1
          ? `(${selector.values.join(', ')})`
          : selector.values[0];
      return `${selector.path} ${
        selector.values.length > 1 ? 'in' : '='
      } ${values}`;
    })
    .join(' AND ');
}

interface Props {
  metric: Metric;
}
export const GovernanceTestOverviewMetricInfo: React.FC<Props> = props => {
  const classes = useStyles();
  const { defaultThresholdColors } = useDefaultThresholdColors();
  const configApi = useApi(configApiRef);

  const colors: MetricThresholdColor[] =
    configApi.getOptional('mesh.governance.metrics.thresholds.colors') ||
    defaultThresholdColors;

  const resourceType = props.metric.resourceType;
  const resourceTypeDisplayName =
    useResourceTypes({
      filters: { name: resourceType },
    }).get(resourceType)?.displayName ?? snakeCaseToTitleCase(resourceType);

  const fields: GenericGridItem[] = [
    {
      label: 'ID',
      value: props.metric.metricId,
      type: 'string',
      colSpan: { xs: 2, sm: 2, lg: 2 },
    },
    {
      label: 'Description',
      value: props.metric.description,
      type: 'string',
      colSpan: { xs: 2, sm: 2, lg: 2 },
    },
    {
      label: 'Version',
      value: props.metric.version,
      type: 'string',
      colSpan: { xs: 2, sm: 1, lg: 1 },
    },

    {
      label: 'Resource Preprocessing',
      value: snakeCaseToTitleCase(props.metric.strategy),
      type: 'string',
      colSpan: { xs: 2, sm: 1, lg: 1 },
    },
    {
      label: 'Scope',
      value: resourceTypeDisplayName,
      type: 'string',
      colSpan: { xs: 2, sm: 1, lg: 1 },
    },
    {
      label: 'Status',
      value: <StatusField status={props.metric.status} />,
      colSpan: { xs: 2, sm: 1, lg: 1 },
    },
    {
      label: 'Context',
      value: snakeCaseToTitleCase(props.metric.context),
      type: 'string',
      colSpan: { xs: 2, sm: 1, lg: 1 },
    },
    {
      label: 'Timing',
      value: snakeCaseToTitleCase(props.metric.timing),
      type: 'string',
      colSpan: { xs: 2, sm: 1, lg: 1 },
    },
    {
      label: 'Trigger',
      value: snakeCaseToTitleCase(props.metric.trigger),
      type: 'string',
      colSpan: { xs: 2, sm: 1, lg: 1 },
    },
  ];

  if (!!props.metric.cronExpression)
    fields.push({
      label: 'Expression',
      value: snakeCaseToTitleCase(props.metric.cronExpression),
      type: 'string',
      colSpan: { xs: 2, sm: 1, lg: 1 },
    });

  if (!!props.metric.domain.min || !!props.metric.domain.max)
    fields.push({
      label: 'Domain',
      value:
        (props.metric.domain.min ? `Min: ${props.metric.domain.min} ` : '') +
        (props.metric.domain.max ? `Max: ${props.metric.domain.max}` : ''),
      type: 'string',
      colSpan: { xs: 2, sm: 1, lg: 1 },
    });

  if (!!props.metric.expression)
    fields.push({
      label: 'Expression',
      value: snakeCaseToTitleCase(props.metric.expression),
      type: 'string',
      colSpan: { xs: 2, sm: 1, lg: 1 },
    });

  fields.push({
    label: 'Interaction Type',
    value: toHumanReadableInteractionType(props.metric.interactionType),
    type: 'string',
    colSpan: { xs: 2, sm: 1, lg: 1 },
  });

  if (props.metric.selectors && props.metric.selectors.length > 0)
    fields.push({
      label: 'Selectors',
      value: extractSelectorSummary(props.metric.selectors),
      type: 'string',
      colSpan: { xs: 2, sm: 1, lg: 1 },
    });

  if (props.metric.tags && props.metric.tags.length > 0)
    fields.push({
      label: 'Tags',
      colSpan: { xs: 2, sm: 1, lg: 1 },
      value: (
        <WbTagsTableCell
          tags={props.metric.tags.map(t => {
            return { tagFQN: t };
          })}
        />
      ),
    });

  if (props.metric.environments && props.metric.environments.length > 0)
    fields.push({
      label: 'Environment',
      value: props.metric.environments.map((env: string) => capitalize(env))[0],
      type: 'string',
      colSpan: { xs: 2, sm: 1, lg: 1 },
    });

  if (!!props.metric.expression && !!props.metric.thresholds.length)
    fields.push({
      label: 'Treshold',
      colSpan: { xs: 2, sm: 2, lg: 2 },
      value: (
        <Box className={classes.tresholdsContainer}>
          {props.metric.thresholds
            .sort((a, b) => (a.value > b.value ? 1 : -1))
            .map(t => (
              <Typography
                key={t.label}
                variant="body1"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                }}
              >
                <Box
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%',
                    backgroundColor: colors.find(c => c.label === t.color)
                      ?.value,
                  }}
                />
                {`${
                  t.label
                    ? t.label
                    : colors.find(c => c.label === t.color)?.defaultLabel
                }${fromExpressionToLabel[props.metric.expression!]} ${t.value}`}
              </Typography>
            ))}
        </Box>
      ),
    });

  if (!!props.metric.expression && props.metric.thresholds.length === 0)
    fields.push({
      label: 'Treshold',
      value: '-',
      type: 'string',
      colSpan: { xs: 2, sm: 1, lg: 1 },
    });

  if (props.metric.externalUrl) {
    fields.push({
      label: 'External URL',
      colSpan: { xs: 4, sm: 4, lg: 4 },
      value: (
        <Link to={props.metric.externalUrl}>
          <WbTruncatedTypographyWrapper value={props.metric.externalUrl} />
        </Link>
      ),
    });
  }
  return (
    <WbCard title="Metric">
      <WbCardContent>
        <WbGenericFieldGrid fields={fields} />
      </WbCardContent>
    </WbCard>
  );
};
