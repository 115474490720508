import { z } from 'zod';
import {
  CompletePermission,
  PermissionEntity,
  Role,
  RoleEntity,
  RolePermission,
  RoleSubject,
  RoleSubjectEntity,
  RoleVisibility,
} from './model';

export const RolesSubjectsFiltersSchema = z
  .object({
    subject: z.string(),
    roleId: z.string(),
    entityRef: z.string().optional().nullable(),
    enabled: z.enum(['true', 'false']).default('false'),
  })
  .partial();

export type RolesSubjectsFilters = z.infer<typeof RolesSubjectsFiltersSchema>;

export type DeleteRolesSubjectsParams = {
  filters?: RolesSubjectsFilters;
  options?: { token?: string };
};

export type GetRolesSubjectsParams = {
  filters?: RolesSubjectsFilters;
  options?: { token?: string };
};

export type GetRolesParams = {
  searchKeyword?: string;
  options?: {
    visibility?: RoleVisibility;
    offset?: number;
    limit?: number;
    token?: string;
  };
};

export type GetUserPermissionsParams = {
  options?: {
    token: string;
    permissionId?: string;
  };
};

export type GetPlatformPermissionsParams = {
  options?: {
    token?: string;
    permissionId?: string;
  };
};

export type AddRolesSubjectsParams = {
  rolesSubjects: RoleSubject[];
  options?: { upsert?: boolean; token?: string };
};

export type AddRolesParams = {
  roles: Role[];
  options?: { upsert?: boolean; token?: string };
};

export type AddRolesPermissionsParams = {
  rolesPermissions: RolePermission[];
  options?: { upsert?: boolean; token?: string };
};

export const RolesPermissionsFiltersSchema = z
  .object({
    roleId: z.string(),
    permissionId: z.string(),
  })
  .partial();

export type RolesPermissionsFilters = z.infer<
  typeof RolesPermissionsFiltersSchema
>;

export type DeleteRolesPermissionsParams = {
  filters?: RolesPermissionsFilters;
  options?: { token?: string };
};

/**
 * RBAC Api definition for frontend clients
 */
export interface RbacApi {
  /**
   * Deletes roles subjects rows according to defined filters
   * @param options
   */
  deleteRolesSubjects(
    params: DeleteRolesSubjectsParams,
  ): Promise<Array<RoleSubjectEntity>>;

  getRolesSubjects(
    params: GetRolesSubjectsParams,
  ): Promise<Array<RoleSubjectEntity>>;

  getRoles(params: GetRolesParams): Promise<RoleEntity[]>;

  getUserPermissions(
    params: GetUserPermissionsParams,
  ): Promise<Array<CompletePermission>>;

  getPlatformPermissions(
    params: GetPlatformPermissionsParams,
  ): Promise<Array<PermissionEntity>>;

  addRolesSubjects(
    params: AddRolesSubjectsParams,
  ): Promise<Array<RoleSubjectEntity>>;

  addRoles(params: AddRolesParams): Promise<Array<RoleEntity>>;

  addRolesPermissions(
    params: AddRolesPermissionsParams,
  ): Promise<Array<RolePermission>>;

  deleteRolesPermissions(
    params: DeleteRolesPermissionsParams,
  ): Promise<Array<RolePermission>>;
}
