import { ReviewEntity } from '@agilelab/plugin-wb-marketplace-common';
import {
  WbCard,
  WbCardActionButton,
  WbCardContent,
  WbFieldLabel,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import { ErrorPanel, Progress } from '@backstage/core-components';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import EditIcon from '@material-ui/icons/Edit';
import {
  CardActions,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { GET_REVIEWS_BY_INSTANCE_ID } from '@agilelab/plugin-wb-marketplace-common';
import { ADD_REVIEW_BY_INSTANCE_ID } from '../../graphql';
import { dataProductRouteRef } from '../../routes';
import { useMarketplaceStyle } from '../../themes';
import { cloneArray } from '../../utils';
import {
  SystemContext,
  UserEntityContext,
} from '../../hooks/DataProductContext';
import { DataProductPageRoutes } from '../DataProductPage/DataProductPageRoutes';
import {
  MAX_COMMENT_LENGTH,
  MIN_COMMENT_LENGTH,
  RatingDetails,
} from './RatingDetails';
import { RatingForm } from './RatingForm';
import { RatingHistogram } from './RatingHistogram';
import { RatingStars } from './RatingStars';

export function parseReviews(data: any): ReviewEntity[] {
  return data.instance[0].reviews as ReviewEntity[];
}

function parseRatingScore(data: any): number {
  return data.instance.at(0)?.reviews_aggregate.aggregate.avg.score || 0;
}

export function sortReviews(reviews: ReviewEntity[]): ReviewEntity[] {
  return cloneArray(reviews).sort((a: ReviewEntity, b: ReviewEntity) =>
    b.timestamp > a.timestamp ? 1 : -1,
  );
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      rating: {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize * 1.5,
      },
      table: {
        '& td': {
          padding: '0.5rem 0.1rem',
        },
      },
      tableCell: {
        border: 0,
      },
    }),
  { name: 'ReviewCard' },
);

export const ReviewsCard = () => {
  const classes = useStyles();
  const commonClasses = useMarketplaceStyle();
  const userEntity = useContext(UserEntityContext);
  const navigate = useNavigate();

  const alertApi = useApi(customAlertApiRef);

  const dataProductRoute = useRouteRef(dataProductRouteRef);
  const dpInstance = useContext(SystemContext);
  const [expanded, setExpanded] = useState(false);
  const [formErrorText, setFormErrorText] = useState('');

  const { loading, error, data } = useQuery(gql(GET_REVIEWS_BY_INSTANCE_ID), {
    variables: {
      id: dpInstance.id,
    },
  });

  const [addReview] = useMutation(ADD_REVIEW_BY_INSTANCE_ID, {
    refetchQueries: [gql(GET_REVIEWS_BY_INSTANCE_ID)],
    onCompleted: (_: any) => {
      setExpanded(false); // close form on submission success
      alertApi.post({
        message: 'Review sent, thank you!',
        severity: 'success',
        timeoutMillis: 5000,
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onError: (e: ApolloError) => {
      setFormErrorText(
        `Something went wrong. try again later! Error: ${e.message}`,
      );
    },
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <WbCard title="Reviews">
        <WbCardContent>
          <ErrorPanel error={error} />
        </WbCardContent>
      </WbCard>
    );
  }

  const rawReviews = parseReviews(data);
  const reviews = sortReviews(rawReviews);
  const ratingScore = parseRatingScore(data);

  const userHasAlreadyReviewed = reviews.find(
    review => review.reviewer === userEntity?.username,
  );
  const isUserOwner = (userEntity?.username || '') === dpInstance.owner;

  const canUserMakeReviews = !isUserOwner && !userHasAlreadyReviewed;
  const noReviewsYet = reviews.length === 0;

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrorText('');

    if (!userEntity?.username) {
      setFormErrorText('An internal error occurred. Try again later.');
      return;
    }

    const formData = new FormData(e.currentTarget);

    const reviewScore = parseInt(
      formData.get('new-review-score')?.toString() || '0',
      10,
    );
    if (reviewScore < 1) {
      setFormErrorText('At least 1 star please!');
      return;
    }
    const reviewComment = formData.get('new-review-comment')?.toString() || '';
    if (reviewComment.length < MIN_COMMENT_LENGTH) {
      setFormErrorText(
        `Please be more descriptive. A review needs at least ${MIN_COMMENT_LENGTH} characters (e.g. "Good")`,
      );
      return;
    }
    if (reviewComment.length > MAX_COMMENT_LENGTH) {
      setFormErrorText(
        `We know you have a lot to say, but a review could contain a maximum of ${MAX_COMMENT_LENGTH} characters (${reviewComment.length}/${MAX_COMMENT_LENGTH}).`,
      );
      return;
    }

    addReview({
      variables: {
        instanceId: dpInstance.id,
        reviewer: userEntity.username,
        reviewer_display_name: userEntity.displayName,
        score: reviewScore,
        short_comment: reviewComment,
        long_comment: reviewComment,
      },
    });
  };

  return (
    <WbCard
      title="Reviews"
      footer={
        !noReviewsYet || canUserMakeReviews ? (
          <>
            <CardActions>
              {!noReviewsYet && (
                <WbCardActionButton
                  label="See all reviews"
                  variant="text"
                  onClick={() =>
                    navigate(
                      dataProductRoute({ id: dpInstance.id.toString() }) +
                        DataProductPageRoutes.REVIEWS_ROUTE,
                    )
                  }
                />
              )}
              {canUserMakeReviews && (
                <WbCardActionButton
                  label="Write a review"
                  variant="contained"
                  startIcon={<EditIcon fontSize="small" />}
                  onClick={handleExpandClick}
                />
              )}
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {userEntity && (
                <RatingForm
                  reviewerDisplayName={userEntity.displayName}
                  onCancel={handleExpandClick}
                  onSubmit={onFormSubmit}
                  errorText={formErrorText}
                />
              )}
            </Collapse>
          </>
        ) : undefined
      }
    >
      <WbCardContent>
        <WbFieldLabel label="Project Score" />
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.rating}>
                {ratingScore.toFixed(2)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <RatingStars
                  rating={ratingScore}
                  clickable={false}
                  variant="small"
                />
              </TableCell>
              <TableCell className={classes.tableCell}>
                {reviews.length || 0} Ratings
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell} colSpan={3}>
                <RatingHistogram reviews={reviews} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br />
        <WbFieldLabel label="Last Review" />
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}>
                {reviews.length > 0 ? (
                  <RatingDetails review={reviews[0]} />
                ) : (
                  <Typography className={commonClasses.text}>
                    No reviews yet
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </WbCardContent>
    </WbCard>
  );
};
