import { get } from 'lodash';
import React, { Fragment, useMemo } from 'react';

import {
  WbCard,
  WbCardContent,
  WbGenericFieldGrid,
} from '@agilelab/plugin-wb-platform';
import { isValidInfo } from '../../utils';
import { GeneralInfo, TechInfoObject } from '../types';
import { Descriptor } from '@agilelab/plugin-wb-marketplace-common';

type Props = {
  title: string;
  configs: string[] | undefined;
  descriptor: Descriptor | Record<string, any>;
  wrapper?: React.FC<{ children?: React.ReactNode }>;
  maxLines?: number;
  maxChars?: number;
  showMoreButton?: boolean;
};

export function useParsedInfo(
  configs: string[],
  descriptor: Record<string, any>,
): Array<GeneralInfo> {
  return useMemo(() => {
    const techInfoObjects: Array<TechInfoObject> = configs.map(techInfo => {
      const info = get(descriptor, techInfo);
      const publicInfo = info?.publicInfo;
      return publicInfo ?? info;
    });

    return techInfoObjects.reduce(
      (acc: Array<GeneralInfo>, obj: TechInfoObject) => {
        if (!obj) return acc;
        for (const info of Object.values<GeneralInfo>(obj).filter(i =>
          isValidInfo(i),
        )) {
          acc.push({
            label: info.label,
            value: info.value,
            type: info.type,
            href: info.href,
          });
        }
        return acc;
      },
      [],
    );
  }, [configs, descriptor]);
}

export const TechnicalInfoCard = ({
  title,
  configs,
  descriptor,
  wrapper = () => <></>,
  maxLines = 3,
  maxChars = 35,
  showMoreButton = true,
}: Props) => {
  const Wrapper = wrapper;

  const parsedInfo: Array<GeneralInfo> = useParsedInfo(
    configs ?? ['info', 'buildInfo', 'deployInfo'],
    descriptor,
  );

  if (!parsedInfo.length) return <Fragment />;

  return (
    <Wrapper
      children={
        <WbCard title={title}>
          <WbCardContent>
            <WbGenericFieldGrid
              maxLines={maxLines}
              maxChars={maxChars}
              showMoreButton={showMoreButton}
              fields={parsedInfo}
            />
          </WbCardContent>
        </WbCard>
      }
    />
  );
};
